/*!
 * Builder CSS for react v.17
 * 
 *  Mobile UI Builder 전용 css  구글색상버전
 * - Component, properties
 *
 *   Author: Bizentro
 *   Date: 2024-07
 */
:root {
  --editor-component-border-color: #eee1e1;
  --active_dropzone-color: #f5f2f2;
  --mobile-element-base-size: 38px;
}

body {
  overflow-x: hidden;
  overflow-y: hidden;
}

/*
   * MOBILE UI BUILDER TREE 
   * -----------------------------------------------------------------------------------
   */

/* mobile-edit-panel */

.mobile-device-canvas {
  overflow: auto;
  height: calc(100vh - 82px);
}

.mobile-device-canvas::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(211, 211, 211, 0);
}
.mobile-device-canvas::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 50px;
}
.mobile-device-canvas::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 50px;
}

/*
   * - Edit panel의 기본 구조에 대한 CSS
   * - drop-zone 밎 layer 간의 간격 등
   * - comp-toolbar css
   * - edit-xxx : Component/Layout의 경우 간격 및 테두리 지정 
   *             (높이 및 너비는 builder-xxx에서 지정)
   * -----------------------------------------------------------------------------------
   */

.edit-panel.mobile .component-select > .comp-toolbar {
  min-width: unset;
}

.mobile.edit-panel::-webkit-scrollbar,
.mobile.edit-panel .filter-container .filter-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(211, 211, 211, 0);
}
.mobile.edit-panel::-webkit-scrollbar-thumb,
.mobile.edit-panel .filter-container .filter-content::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 50px;
}
.mobile.edit-panel::-webkit-scrollbar-track,
.mobile.edit-panel .filter-container .filter-content::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 50px;
}

.mobile.edit-panel .form-control {
  position: relative;
  width: 100%;
  height: var(--mobile-element-base-size);
  padding: 0 4px;
  border: 1px solid #ccc;
  border-radius: 0;
  color: #444;
  font-size: 13px;
}

.mobile.edit-panel textarea.form-control {
  height: auto;
}

.mobile.edit-panel .mobile-edit-panel-area {
  overflow: auto;
  position: relative;
}

.mobile.edit-panel .mobile-edit-panel-area::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(211, 211, 211, 0);
}
/* .mobile-edit-panel .mobile-edit-panel-area:hover::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  } */
.mobile.edit-panel .mobile-edit-panel-area::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 50px;
}
.mobile.edit-panel .mobile-edit-panel-area::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 50px;
}

.mobile.edit-panel .text-control {
  height: var(--mobile-element-base-size);
  line-height: 30px;
  display: block;
  font-size: 13px;
}
.mobile.edit-panel .editor-form-group .input-group > .btn,
.mobile.edit-panel button.datepicker-icon {
  height: var(--mobile-element-base-size);
  width: var(--mobile-element-base-size);
  z-index: 10;
}

.mobile-builder .mobile.edit-panel .mobile-device-wrapper {
  font-size: 14px;
}

/*** editor setting start ****/

/** 뷰포트 탭 */
/*.mobile-edit-panel .viewport-tab {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-edit-panel .viewport-tab .form-select {
  width: 200px;
} */

/*** editor setting end ****/

.mobile.edit-panel .edit-tab {
  height: var(--mobile-element-base-size);
}

.mobile.edit-panel .page-container {
  display: inline-grid;
  background: #fff;
  margin-top: 0px;
  position: relative;
  padding-top: 0.3rem;
  padding-right: 0.5rem;
  padding-bottom: 0.3rem;
  padding-left: 0.5rem;
}

.mobile.edit-panel .page-container.popup-container {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  padding-right: 0.5rem;
  padding-bottom: 0;
  padding-left: 0.5rem;
  overflow: auto;
  border-radius: 12px;
}

.mobile.edit-panel .mobile-modal-footer {
  border: none;
  background: transparent;
  position: absolute;
  bottom: 45px;
  max-height: 100%;
  height: auto;
  width: calc(100% - 1rem);
}

.mobile.edit-panel .pop_wrap .page {
  /* max-height: calc(100vh - 420px); */
  margin-bottom: 50px;
}

.edit-panel:not(.preview-panel) .page-container .page .component-hover {
  border: 1px solid #ececec;
}

/** drop-zone 영역 높이 10->4 */
.mobile.edit-panel .drop-zone {
  height: 6px;
}

/* dashboard */
.mobile.edit-panel .editor-column {
  /* border: 1px solid var(--editor-component-border-color); */
  border-left: none;
  border-top: none;
  border-bottom: none;
  padding: 0;

  justify-content: center;
  width: 100%;
}

.mobile.edit-panel .editor-column.isLast {
  border-right: none;
}

.mobile.edit-panel .editor-columns {
  width: 100%;
  justify-content: center;
}
.mobile.edit-panel .editor-container .editor-columns {
  width: 100%;
}

.mobile.edit-panel .editor-flex-column {
  justify-content: center;
  min-height: unset;
}

.mobile.edit-panel .editor-flex-column > .drop-zone {
  min-height: unset;
}

.mobile.edit-panel .editor-base {
  display: flex;
  flex-wrap: wrap;
}

.mobile.edit-panel .widget-body .editor-base {
  display: block;
}

.mobile.edit-panel .editor-widget-containter {
  display: block;
}

.mobile.edit-panel .editor-form {
  min-height: 50px;
}
.mobile.edit-panel .editor-form::not(:has(.editor)) {
  min-height: 50px;
  border: 1px solid var(--editor-component-border-color);
}
.mobile.edit-panel .editor-container .editor-column {
  border: 1px solid var(--editor-component-border-color);
}

/* 모바일 화면에서만 적용될 스타일 */
@media (max-width: 768px) {
  /* 768px 이하로 모바일 화면에 맞춤 */
  .mobile.edit-panel .editor-form,
  .mobile.edit-panel .editor-grid,
  .mobile.edit-panel .editor-container,
  .mobile.edit-panel .editor-input-group,
  .mobile.edit-panel .editor-headling {
    width: calc(100% - 30px); /* 모바일 화면에서는 30px을 빼고 설정 */
  }
}

.mobile.edit-panel .editor-form-group {
  border: unset;
  padding: 0;
}

/* hidden row*/
.mobile.edit-panel .editor-row {
  border: 0;
}

.mobile.edit-panel .editor-component-wrap {
  width: 100%;
  padding: 0 12px;
  /* width : 100%;  input group이 이상하게나옴*/
}

.mobile.edit-panel .editor-mui-button {
  width: 100%;
}

.mobile.edit-panel .editor-headling,
.mobile.edit-panel .editor-html {
  min-height: var(--mobile-element-base-size);
}
.mobile.edit-panel .form-label {
  padding: 0;
}
.mobile.edit-panel .form-group .search-form-label {
  font-size: 16px;
  font-weight: 600;
  text-align: left !important;
}
/* 입력 폼 */
.mobile.edit-panel .form-group .save-form-label {
  background: unset;
  text-align: left !important;
}

/* filter 폼 */
.mobile.edit-panel .form-group .filter-form-label {
  font-size: 16px;
  background: unset;
  text-align: left !important;
}

.mobile.edit-panel .form-group:has(> label.horizontal-label) {
  display: flex !important;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  align-items: center;
}

.mobile.edit-panel .form-group.row {
  margin: 0px;
}

/* DND Action */
.mobile.edit-panel .whole-wrap.active > .editor-column > .drop-zone ,
/*.mobile.edit-panel .page-container.dragover > .page > .drop-zone ,*/
.mobile.edit-panel .drop-zone.active {
  width: 100%;
  height: 40px;
  border: 1px dashed #ccc;
  border-radius: 2px;
  background: var(--active_dropzone-color);
  transition: all 0.3s ease;
}

/*
   * Edit panel - builder-xxx => watermark
   * -----------------------------------------------------------------------------------
   */
.edit-panel .builder-container {
  background-image: unset;
}

.edit-panel .builder-row {
  background-image: unset;
}

.edit-panel .builder-column {
  background-image: unset;
}

.edit-panel .builder-block {
  background-image: unset;
}
.edit-panel .builder-form {
  background-image: unset;
}

.mobile-viewport-add {
  border-radius: 20px;
  border-color: #1976d2;
  background-color: white;
  color: #1976d2;
  padding: 2px 15px;
  margin-top: 5px;
  margin-bottom: 10px;
  background-image: none;
}

.mobile-viewport-cancel {
  border-radius: 20px;
  border-color: #1976d2;
  background-color: white;
  color: #1976d2;
  padding: 2px 15px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
  background-image: none;
}

.mobile-viewport-width {
  position: relative;
  width: 50px;
  height: 30px;
  padding: 0 4px;
  border: 1px solid #ccc;
  border-radius: 0;
  color: #444;
  font-size: 13px;
  background-color: white;
  text-align: center;
  margin: 0 5px 0 10px;
}

.mobile-viewport-height {
  position: relative;
  width: 50px;
  height: 30px;
  padding: 0 4px;
  border: 1px solid #ccc;
  border-radius: 0;
  color: #444;
  font-size: 13px;
  background-color: white;
  text-align: center;
  margin: 0 0 0 5px;
}

.mobile-filter-pannel-header {
  text-align: left;
  height: 56px;
}

.mobile-filter-pannel-hidden {
  background-color: #fff;
  color: #000;
  font-size: 24px;
  border: 0;
}

.mobile-search-wrap {
  /* border-top: 2px solid #a18038 !important; */
  /* background: #f5f5f5 !important; */
  /* border-left: 1px solid #dadada !important;
  border-right: 1px solid #dadada !important;
  border-bottom: 1px solid #dadada !important; */
}
.mobile-search-wrap .editor-column,
.mobile-search-wrap .editor-row {
  /* background: #f5f5f5 !important; */
}
/*
.search-wrap button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 30px !important;
  background: #d9bf31 !important;
  border: 1px solid #c7ad23 !important;
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  text-align: center !important;
  box-shadow: none !important;
  border-radius: unset !important;
}
*/

.mobile-search-wrap .form-group .form-depend-label {
  /* margin-bottom: 0; */
  color: #000;
  /* font-size: 14px; */
  /* font-weight: 600; */
  text-align: right;
  white-space: nowrap;
  padding-right: 6px;
}
.mobile-search-wrap .form-group .form-depend-label.required:after {
  content: "*";
  top: 50%;
  right: 5px;
  padding-right: 3px;
  margin-top: -7px;
  color: #f00;
  font-size: 14px;
  line-height: 14px;
  font-weight: 900;
}

/* 모바일 디자인 적용 */
.mobile-builder {
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
}

/* 모바일 빌더 좌측 메뉴 래퍼 */
.mobile-builder .tree-component-wrapper {
  width: 20%;
  max-width: 400px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: #4a4747;
  position: relative;
}
.mobile-builder .tree-component-wrapper .tree-component-resizer {
  position: absolute;
  border: 1px solid;
  width: 100%;

  align-items: center;
  display: flex;
  height: 5px;
  justify-content: center;
}
.mobile-builder .tree-component-wrapper .tree-component-resizer .resizer-bar {
  width: 25%;
  height: 2px;
  background-color: gray;
}
.mobile-builder .tree-component-wrapper .tree-component-resizer:hover {
  cursor: row-resize;
}

.mobile-builder .tree-component-wrapper .control-sidebar {
  margin-top: 5px;
  display: block;
}

/* 트리 컨테이너 */
.mobile-builder .builderTree-container {
  display: block;
  position: static;
  width: 100%;
  height: 50%;
  transition: 0.5s;
  border-bottom: 1px solid #333333;
}

.mobile-builder .builderTree-container .builderTree-body {
  box-shadow: none;
}

/*컴포넌트 탭 리디자인*/
.mobile-builder .control-sidebar {
  position: static;
  background: #222d32;
  color: #b8c7ce;
  width: 100%;
  padding: 0;
}
.mobile-builder .control-sidebar .service-component-panel .accordion,
.control-sidebar .basic-component-panel .accordion {
  min-height: auto;
  height: auto;
}

.mobile-builder .tab-content {
  /*총 높이 - 탭 높이*/
  height: calc(100% - 32.5px);
  overflow: auto;
}
.tab-content::-webkit-scrollbar {
  width: 3px;
  background-color: lightgray;
}
.tab-content::-webkit-scrollbar-thumb {
  background-color: gray;
}
.tab-content::-webkit-scrollbar-track {
  background-color: #303030;
}

/* Editor panel 넓이 수정*/

.mobile-builder .mobile.edit-panel {
  width: 100%;
  max-width: calc(100% - 20%);
  margin: 0px;
}

.mobile-builder .viewport-tab {
  gap: 10px;
}

.mobile-builder .viewport-tab .size-input {
  width: 50px;
  height: 27px;
}

.mobile-device-canvas {
  display: flex;
  justify-content: center;
  perspective: 1000px; /* 3D 효과를 위한 원근법 */
}

.mobile-device-canvas .device-frame {
  position: absolute;
  transform-origin: top center;
  transform-style: preserve-3d;
  transition: transform 0.6s ease, z-index 0.6s;
  /* top: 20;
  left: 30; */
}
.device-frame .viewport-toolbar {
  height: 23px;
  border: 1px solid #0b5ed7;
  background-color: #0b5ed7;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: whitesmoke;
  position: absolute;
  top: -10px;
  left: 25px;
  cursor: move;
  z-index: 2;
  padding-left: 5px;
  padding-right: 5px;
}

.mobile-device-wrapper {
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 25px;
  padding: 5px 5px 5px 5px;
  background-color: #8d8d8d;
  margin-top: 1rem;
  backface-visibility: hidden;
}
.mobile-device-wrapper.dragging {
  background-color: #acacaca7;
}

.mobile-device-wrapper input:not([type="radio"], [type="checkbox"]),
.mobile-device-wrapper button {
  height: 42px;
}

/*viewport direction vertical */
.mobile-device-wrapper .device-top {
  position: absolute;
  left: 3px;
  top: 3px;
  width: calc(100% - 6px);
  height: 25px;
  background-color: transparent;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.mobile-device-wrapper .divice-middle {
  height: calc(100% - 5px);
  z-index: 1;
  position: absolute;
  width: calc(100% - 6px);
  top: 3px;
  left: 3px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-left: 3px solid lightgray;
  border-right: 3px solid lightgray;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  overflow-y: auto;
}

.mobile-device-wrapper .device-bottom {
  position: absolute;
  left: 3px;
  z-index: 2;
  bottom: 3px;
  width: calc(100% - 6px);
  height: 45px;
  display: grid;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 20px;
  font-weight: bold;
  justify-items: center;
  background-color: #c9c9c9ca;
}

.mobile-device-wrapper .device-bottom.ios div:nth-child(2) {
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
}

.mobile-device-wrapper .device-bottom .ios-underbar {
  height: 5px;
  width: 100%;
  border-radius: 10px;
  background-color: gray;
}

/*viewport direction horizontal */
.mobile-device-wrapper.horizontal {
}

.mobile-device-wrapper.horizontal .device-top {
  position: absolute;

  left: 3px;
  top: 3px;
  width: 25px;
  height: calc(100% - 6px);
  background-color: transparent;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 0;
}

.mobile-device-wrapper.horizontal .divice-middle {
  height: calc(100% - 5px);
  position: absolute;
  width: calc(100% - 6px);
  top: 3px;
  left: 3px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-top: 3px solid lightgray;
  border-bottom: 3px solid lightgray;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  overflow-y: auto;
}

.mobile-device-wrapper.horizontal .device-bottom {
  position: absolute;
  right: 3px;
  top: 3px;
  left: unset;
  bottom: 10px;
  width: 45px;
  height: calc(100% - 6px);
  display: grid;
  border-bottom-left-radius: 0;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: none;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  grid-template-areas: "item3" "item2" "item1"; /* 순서를 역방향으로 지정 */
}

.mobile-device-wrapper.horizontal .device-bottom > div:first-child {
  grid-area: item1;
}

.mobile-device-wrapper.horizontal .device-bottom > div:nth-child(2) {
  grid-area: item2;
}

.mobile-device-wrapper.horizontal .device-bottom > div:last-child {
  grid-area: item3;
  rotate: 270deg;
}

.mobile-device-wrapper.horizontal .device-bottom.ios div:nth-child(2) {
  justify-content: flex-end;
  padding-bottom: 0;
  padding-right: 8px;
}
.mobile-device-wrapper.horizontal .device-bottom.ios .ios-underbar {
  height: 100px;
  width: 5px;
}

.device-frame iframe > *::-webkit-scrollbar {
  width: 3px;
  background-color: lightgray;
}
.device-frame iframe > *::-webkit-scrollbar-thumb {
  background-color: gray;
}
.device-frame iframe > *::-webkit-scrollbar-track {
  background-color: #303030;
}

.mobile-builder .mobile.edit-panel .page-container {
  width: 100%;
  padding-top: 25px;
}

.floating-property-tab.mobile {
  z-index: 10;
}

.mobile-builder .control-sidebar .properties-component-panel .accordion {
  height: auto;
}

.mobile-builder .edit-button-group .text {
  min-width: 55px;
  padding: 0px 5px;
}
.mobile-builder .edit-button-group .text.disabled {
  background-color: lightgray;
  border-color: gray;
  color: black;
}
.edit-tab .edit-button-group > *:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left: 1px solid black;
}
.mobile-builder .edit-tab select {
  text-align: center;
}
.mobile-builder .page .btn {
  text-align: center;
  border-radius: 8px;
  -webkit-border-radius: 8px;
}

.mobile-edit-panel .editor-columns {
  padding: 0;
}

/* filter Overlay */
.mobile-builder .filter-overlay,
.mobile-builder .filter-container {
  position: fixed;
  top: 40px;
  left: 8px;
  width: calc(100% - 25px);
  height: calc(100% - 80px);
}

.mobile-builder .filter-overlay {
  position: fixed;
  top: 16px;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0s 0.3s;
  z-index: 1000;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
}

.mobile-builder .filter-overlay.visible {
  visibility: visible;
  opacity: 1;
  transition-delay: 5s;
}

/* filter Container */
.mobile-builder .filter-container {
  background: white;
  /*
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  */
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.mobile-builder .filter-container.slide-in {
  transform: translateX(0);
}

.mobile-builder .filter-container.slide-out {
  transform: translateX(100%);
}

/* filter Close Button */
.mobile-builder .filter-close-button {
  position: absolute;
  top: 0px;
  left: 10px;
  font-size: 18px;
  color: #000;
  /* color: #007bff; */
  background: none;
  border: none;
  cursor: pointer;
}

/* filter Content */
.mobile-builder .filter-content {
  padding-top: 16px;
  padding-left: 4px;
  padding-right: 4px;
  overflow-y: auto;
  margin-top: 40px;
  height: 100%;
}

.mobile-builder .filter-btn-footer {
  padding: 6px 12px 4px 12px;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

/* 버튼 공통 스타일 */
.mobile-builder .filter-btn {
  width: 100%;
  height: 40px;
  gap: 12px;
  border-radius: 10px;
}

/* 초기화 버튼 스타일 (하얀색 계열) */
.mobile-builder .filter-reset-btn {
  border: 1px solid #009b71;
  color: #009b71;
  background: #ffffff;
}

/* 적용 버튼 스타일 (남색 계열) */
.mobile-builder .filter-apply-btn {
  background-color: #009b71;
  color: #ffffff;
  border: none;
}

.mobile-builder .editor-amchart3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ------------------- mobile File Upload ------------------- */
.mobile-builder .fileupload-btn {
  height: 38px;
  padding: 10px 0px;
  background: #e7f7f3;
  color: #009b71;
  box-shadow: none;
  font-size: 14px;
  line-height: 18px;
  font-family: "Pretendard", Pretendard, -apple-system, BlinkMacSystemFont,
    "Noto Sans KR", sans-serif !important;
}

.mobile-builder .fileupload-btn:hover {
  background-color: #e7f7f3;
  box-shadow: none;
}

.mobile-builder .fileupload-list-btn {
  width: 0;
  position: absolute;
  right: 8px;
  min-width: 24px;
  padding: 0;
}

.mobile-builder .fileupload-input {
  border-radius: 6px !important;
  width: 100%;
  height: 42px;
}

.mobile-builder .fileupload-list-btn span {
  fill: #7e7e7e;
  height: 24px;
  width: 24px;
  position: relative;
  background: url("/src/images/daafIcon/fileupload.svg") center;
  background-repeat: no-repeat;
}

/* radio & checkbox */

.mobile-builder .mobile.edit-panel .mobile-device-wrapper .form-check {
  display: flex;
  align-items: center;
}

.mobile-device-wrapper input[type="checkbox"],
.mobile-device-wrapper input[type="radio"] {
  height: 16px;
  width: 16px;
  margin: 0;
}

.mobile-device-wrapper .builder-radiobutton .form-group .input-col {
  display: flex;
}

/*-------------------------------------- Mobile 전용 Heading --------------------------------------*/

/* 모바일 프로그램 타이틀 */
.mobile-device-wrapper mh.editor-headling.tit-grid {
  font-size: 18px;
  line-height: 130%;

  font-weight: 600;
  height: 44px;
}

.mobile-device-wrapper h1.tit-grid {
  font-size: 27px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0;
}

.mobile-device-wrapper h2.tit-grid {
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0;
}

.mobile-device-wrapper h3.tit-grid {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0;
}

.mobile-device-wrapper h4.tit-grid {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
}

.mobile-device-wrapper h5.tit-grid {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
}

.mobile-device-wrapper h6.tit-grid {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
}

/*-------------------------------------- Button --------------------------------------*/

.mobile-builder .mobile.edit-panel .mobile-device-wrapper .btn,
.mobile-builder
  .mobile.edit-panel
  .mobile-device-wrapper
  .btn-group.builder-block
  .btn {
  border-radius: 6px;
  min-height: 42px;
  min-width: 42px;
  padding: 0px 20px 0px 20px;
}

.mobile-builder .mobile.edit-panel .mobile-device-wrapper .btn:has(svg),
.mobile-builder
  .mobile.edit-panel
  .mobile-device-wrapper
  .btn-group.builder-block
  .btn:has(svg) {
  padding: 0px 20px 0px 18px;
}
.mobile-builder .mobile.edit-panel .mobile-device-wrapper .btn svg {
  height: 24px;
  width: 24px;
  padding: 3px;
}

.mobile-builder
  .mobile.edit-panel
  .mobile-device-wrapper
  .btn-default.basic-outline {
  border: 1px solid #009b71;
  color: #009b71;
}

.mobile-builder
  .mobile.edit-panel
  .mobile-device-wrapper
  .btn-default.basic-primary {
  border: none;
  background: #009b71;
  color: #ffffff;
}

.mobile-builder .mobile.edit-panel .mobile-device-wrapper .btn.btn-search {
  width: auto;
  padding: 9px;
  background: #009b71;
  color: #ffffff;
  border: none;
}

.mobile-builder
  .mobile.edit-panel
  .mobile-device-wrapper
  .builder-row:has(label)
  .btn-search {
  margin-top: 32px;
}

.mobile-builder
  .mobile.edit-panel
  .mobile-device-wrapper
  .btn.btn-filter.filter-icon {
  border: none;
  width: 42px;
  height: 42px;
  width: auto;
  padding: 9px;
}

.mobile-builder .mobile.edit-panel .mobile-device-wrapper .btn.btn-borderless {
  border: none;
}

/*-------------------------------------- 기타 Component --------------------------------------*/

/* textarea */
.mobile-builder .mobile.edit-panel .mobile-device-wrapper textarea {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  padding: 8px;
  outline: none;
  box-shadow: none;
}

.mobile-builder .mobile.edit-panel .mobile-device-wrapper label {
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  padding-top: 0px;
}

.mobile-builder .mobile.edit-panel .mobile-device-wrapper {
  & .form-select {
    height: 42px;
  }

  & .builder-rangedatepicker,
  .builder-singdatepicker {
    & .input-group input {
      height: 42px;
      border-radius: 6px;
      border: 1px solid #d9d9d9;
      box-shadow: none;
      padding: 0px 32px 0px 8px;
    }
    & .datepicker-icon {
      padding: 9px;
      background: none;
      position: absolute;
      right: 0;
      border: none;
    }
  }
}
