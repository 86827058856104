.page-content .loading-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-content .expand-collapse-button-group {
  display: flex;
  margin-bottom: 5px;
  gap: 3px;
}

.page-content .expand-collapse-button-group .expand-icon:focus,
.page-content .expand-collapse-button-group .expand-icon:hover,
.page-content .expand-collapse-button-group .collapse-icon:focus,
.page-content .expand-collapse-button-group .collapse-icon:hover {
  background-color: #e5f3ff;
  cursor: pointer;
}

.page-content .total-search-container {
  display: flex;
}

.page-content .total-search-container .side-bar-container {
  width: 50px;
  height: calc(100vh - 150px);
  border-right: 1px solid #ecf0f5;
}

.page-content .total-search-container .side-bar-container .nav-link {
  padding: 1rem;
}

.page-content .total-search-container .side-bar-container .nav-link:focus,
.page-content .total-search-container .side-bar-container .nav-link.active {
  border-left: 2px solid #3c8dbc;
}

.page-content .total-search-container .total-search-form-container {
  flex-grow: 1;
  padding: 10px;
  max-height: calc(100vh - 150px);
  overflow: auto;
}

.page-content
  .total-search-container
  .total-search-form-container
  .total-search-condition-container
  .nav-tabs {
  border-bottom: none;
  gap: 10px;
}

.page-content
  .total-search-container
  .total-search-form-container
  .total-search-condition-container
  .nav-tabs
  .nav-link {
  border-radius: 2em;
  border: none;
  background-color: #f6f8fc;
  color: #7a86a2;
  padding: 7px 15px;
}

.page-content
  .total-search-container
  .total-search-form-container
  .total-search-condition-container
  .nav-tabs
  .nav-item.show
  .nav-link,
.page-content
  .total-search-container
  .total-search-form-container
  .total-search-condition-container
  .nav-tabs
  .nav-link.active {
  background-color: #008aec;
  color: #ffffff;
}

.page-content
  .total-search-result-container
  .property-tab-container
  .floating-property-tab {
  top: 0px !important;
  height: calc(100vh - 150px) !important;
}

.page-content .search-result-accordion-header-container {
  display: flex;
}

.page-content .search-result-accordion-header {
  width: fit-content;
  font-size: 12px;
}

.page-content .search-result-accordion-header:focus,
.page-content .search-result-accordion-header:hover {
  background-color: #e5f3ff;
  cursor: pointer;
}

.page-content .search-result-accordion-header-button-container {
  margin-left: 3px;
}

.page-content .search-result-accordion-header-button-containerr:focus,
.page-content .search-result-accordion-header-button-container:hover {
  background-color: #e5f3ff;
  cursor: pointer;
}

.page-content .search-result-title:focus,
.page-content .search-result-title:hover {
  background-color: #e5f3ff;
  cursor: pointer;
}

.page-content .search-result-accordion-item {
  display: flex;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 20px;
}

.page-content .search-result-accordion-item .search-result-title {
  font-size: 12px;
}

.page-content .search-result-button-group {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 5px;
}
.page-content .search-result-button-group .button-icon {
  border-radius: 0.375rem;
  cursor: pointer;
}

.page-content .search-result-button-group .button-icon:hover,
.page-content .search-result-button-group .button-icon:focus {
  background-color: #e5f3ff;
}

.page-content .search-result-button-group .button-icon:hover svg,
.page-content .search-result-button-group .button-icon:focus svg {
  color: #0c63e6;
}

.page-content .workflow-search-result-container {
  display: flex;
  height: calc(100vh - 170px);
}

.page-content .workflow-search-result-container .resize-bar {
  cursor: ew-resize;
  width: 1px;
  background-color: #ecf0f5;
}

.page-content .workflow-search-result-container .resize-bar:focus,
.page-content .workflow-search-result-container .resize-bar:hover,
.page-content .workflow-search-result-container .resize-bar:active {
  background: #0078d4 !important;
  transform: scaleX(4);
  z-index: 10;
}

.page-content .workflow-search-result-container .workflow-container {
  margin-top: -10px;
}

.page-content
  .workflow-search-result-container
  .workflow-container
  .reactflow-wrapper.workflow {
  height: calc(100vh - 160px);
}

.page-content
  .workflow-search-result-container
  .workflow-container
  .reactflow-wrapper.workflow
  .flow-editor-wrapper {
  height: calc(100vh - 190px);
}
