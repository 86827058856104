/*!
 * runtime css for react v.17
 *
 * Runtime환경의 CSS를 migration한 CSS
 * - Builder editor의 결과 화면의 design을 Runtime환경과 유사하게 만들기 위함
 * 
 *   Author: Bizentro
 *   Date: 2021-02
 */
.tit-grid {
  display: flex;
  align-items: center;
}

.tit-grid::before {
  content: "";
  background-image: url(../../images/icon/ico_tit_grid.png);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  color: #393b42;
  display: block;
  padding-left: 20px;
}
.grid-excel-down-parentheses {
  font-weight: 600;
  font-size: 16px;
  color: #909090;
}
.grid-excel-down-font {
  font-weight: 600;
  font-size: 16px;
  color: #009b71;
}

.search-wrap {
  border-top: 2px solid #a18038 !important;
  background: #f5f5f5 !important;
  border-left: 1px solid #dadada !important;
  border-right: 1px solid #dadada !important;
  border-bottom: 1px solid #dadada !important;
}
.search-wrap .editor-column,
.search-wrap .editor-row {
  background: #f5f5f5 !important;
}
/*
.search-wrap button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 30px !important;
  background: #d9bf31 !important;
  border: 1px solid #c7ad23 !important;
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  text-align: center !important;
  box-shadow: none !important;
  border-radius: unset !important;
}
*/

.search-wrap .form-group .form-depend-label {
  /* margin-bottom: 0; */
  color: #000;
  /* font-size: 14px; */
  font-weight: 600;
  text-align: right;
  white-space: nowrap;
  padding-right: 6px;
}
.search-wrap .form-group .form-depend-label.required:after {
  content: "*";
  top: 50%;
  right: 5px;
  padding-right: 3px;
  margin-top: -7px;
  color: #f00;
  font-size: 14px;
  line-height: 14px;
  font-weight: 900;
}
.alpha-grid {
  position: relative;
  border-top: 2px solid #a18038 !important;
  /*
  border-bottom: none !important;
  border-left: none !important;
  */
}
.alpha-grid .form-group .form-depend-label {
  background: #f5f5f5;
  text-align: left;
}
.alpha-grid .form-group .form-depend-label.required:after {
  content: "*";
  top: 50%;
  right: 5px;
  margin-top: -7px;
  color: #f00;
  font-size: 14px;
  line-height: 14px;
  font-weight: 900;
}

.uni-table .editor-grid-columns .editor-grid-header {
  border-top: 2px solid #a18038 !important;
  border-collapse: collapse;
  white-space: nowrap;
}

.uni-table > :not(:first-child) {
  border-top: unset !important;
}

.editor-gird-toolbar-buttons > button {
  display: inline-flex !important;
  align-items: center !important;
  height: 26px !important;
  padding: 0 4px !important;
  background: #fff !important;
  border: 1px solid #b0b0b0 !important;
  color: #1b1b1b !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  cursor: pointer !important;
  box-shadow: none !important;
  border-radius: unset !important;
  width: max-content;
}

.pop_wrap .pop_head {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.2rem;
  background: #425077;
  color: #fff;
  font-size: 24px;
  min-height: 60px;
}

.pop_wrap .page {
  padding: 10px 20px;
}

.pop_wrap .pop_head .tit_pop {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}

.pop_wrap .pop_head .btn_close {
  width: 25px;
  height: 25px;
  background: url(../../images/icon/ico_close.png) no-repeat center center;
}

/*
.ui-modal-footer .btn-form {
  display: inline-flex;
  height: 26px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
  padding: 0 20px;
  color: #fff;
  background: #755b20;
  border: 1px solid #453208;
}
*/
