.layout-container {
  --default-color: #1d4791;
  --sub-color: #6089d1;
  height: 100%;
  width: 100%;
}

.ls-container {
  .ls-header {
    display: flex;

    padding-top: 24px;
    font-size: 17px;
    font-weight: 600;
  }
  .ls-description {
    font-size: 14px;
    display: flex;
  }

  .ls-content {
    background-color: rgb(233, 233, 233);
    height: auto;
    border-radius: 10px;
    padding: 40px;
    padding-bottom: 20px;

    .layout-sample {
      background-color: white;
      height: 360px;
      border-radius: 10px;
      margin-left: 30px;
      margin-right: 30px;
    }

    .layout-sample-selected {
      background-color: rgb(246, 249, 255);
      height: 360px;
      border-radius: 10px;
      margin-left: 30px;
      margin-right: 30px;
      border: #7aa8f8 1px solid;
      box-shadow: 5px 5px 5px rgb(197, 197, 197);
    }

    .layout-label {
      font-size: 14px;
      color: black;
      text-align: center;
      margin-top: 20px;
    }

    .layout-data {
      height: 280px;
      text-align: center;
      margin-top: 30px;
    }

    .layout-radio-btn {
      margin-left: 30px;
    }
    .navi-card {
      height: 130px;
      width: 100px;
      background-color: white;
      border-radius: 10px;
      margin: 10px;

      justify-content: center;
      align-items: center;

      & .navi-card-label {
        font-size: smaller;
        text-align: center;
      }

      & .navi-card-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
      }
      & .navi-card-icon svg {
        height: 50px;
        width: 50px;
        color: rgb(85, 85, 85);
      }

      &.selected {
        background-color: rgb(85, 85, 85);
        color: white;

        & .navi-card-icon svg {
          color: white;
        }
      }
    }

    .navi-card-plus {
      border: 1px solid rgb(82, 82, 82);
      background-color: rgba(255, 255, 255, 0.2);
    }

    .navi-detail {
      position: relative;
      right: 0px;
      background-color: white;
      height: auto;
      border-radius: 10px;
      padding: 10px;
    }

    .navi-detail-row {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .nav-card-delBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -11.3rem;
      left: 7.4rem;
      cursor: pointer;
      border-radius: 50%;
      background-color: red;
      margin: 0;
      width: 20px;
      height: 20px;
    }
    .nav-card-delBtn svg {
      width: 15px;
      color: white;
    }

    .bottom-nav-save-btn {
      float: right;
      position: relative;
      left: 15px;
      top: 10px;
      height: 10px;
    }

    .bottom-nav-save-btn svg {
      margin-right: 5px;
    }
  }

  .template-radio {
    /* position: relative; */
    z-index: 10;
    /* left: 0;
    top: 0; */
    margin-top: 2rem;
    height: 50px;
    width: 30rem;
  }

  .top-nav-template-selected {
    display: flex;
    align-items: center;
    background-color: rgb(246, 249, 255);
    border-radius: 5px;
    height: 4rem;
    width: 22rem;
    padding: 10px 10px 10px 15px;
    border: #7aa8f8 1px solid;
    box-shadow: 5px 5px 5px rgb(197, 197, 197);
  }

  .top-nav-right {
    margin-left: auto;
    margin-right: 5px;
    height: 25px;
    width: 25px;

    color: rgb(29, 71, 145);

    svg {
      width: 25px;
      height: auto;
    }
  }

  .upload-btn {
    background-color: white;
    border-radius: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgray;
  }

  .upload-btn .svg {
    height: 20px;
    width: 20px;
    size: 15px;
  }

  .theme-box {
    background-color: white;
    height: 200px;
    max-width: 150px;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
    &.selected {
      background-color: rgb(246, 249, 255);
      border: #7aa8f8 1px solid;
      box-shadow: 5px 5px 5px rgb(197, 197, 197);
    }
  }

  .theme-custom-box {
    background-color: white;
    height: 200px;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
  }

  .theme-custom-box-selected {
    background-color: rgb(246, 249, 255);
    height: 200px;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    border: #7aa8f8 1px solid;
    box-shadow: 5px 5px 5px rgb(197, 197, 197);
  }

  .theme-content-box {
    height: 150px;
    width: 110px;
    border-radius: 10px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid rgb(165, 165, 165);
  }

  .theme-custom-content-box {
    height: 150px;
    width: 100%;
    border-radius: 10px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid rgb(165, 165, 165);
    padding: 10px;
    padding-top: 40px;
    text-align: center;
  }

  .custom-content {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .color-theme-title {
    height: 15px;
    width: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .color-theme-sub {
    height: 15px;
    width: 80%;
    margin-top: 10px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .theme-label {
    font-size: 14px;
    color: black;
    text-align: center;
    margin-top: 5px;
  }
  .theme-radio-btn {
    margin-left: 5px;
    justify-content: center;
    display: flex;
  }
}
.top-nav-template-box-wrapper {
  padding: 10px;
  background-color: white;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  &.selected {
    background-color: rgb(246, 249, 255);
    border: #7aa8f8 1px solid;
    box-shadow: 5px 5px 5px rgb(197, 197, 197);
  }

  .top-nav-template-box {
    border: 1px solid gray;
  }
}
.top-nav-template-box {
  height: 100%;
  position: relative;
  border-radius: 2px;
  width: 375px;

  & .top-nav-template {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 5px;
    height: 44px;
    padding: 0 16px 0 19.75px;
    gap: 10px;

    & .top-nav-content {
      display: flex;
      align-items: center;
      &:has(svg) {
        font-size: 24px;
        justify-content: center;
      }
      &:has(span) {
        color: #1d4791;
        font-weight: bold;
        font-size: 18px;
        padding-left: 10px;
      }
    }
  }

  &.selected {
    outline: 1px solid #1d4791;
  }
}

.ls-preview-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
  align-items: center;

  .preview-part {
    height: 670px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ls-preview {
      border: 1px solid rgb(49, 49, 49);
      background-color: white;
      border-radius: 5px;
      font-size: 15px;
      /* 뷰포트 375 812 를 동일한 비율로 축소함 */
      box-shadow: 5px 5px 5px rgb(197, 197, 197);
      position: relative;

      &.android {
        height: 640px;
        width: 360px;
        & .ls-bottom-nav {
          height: 80px;
        }
      }

      &.ios {
        height: 640px;
        width: 294px;
        & .ls-bottom-nav {
          height: 71px;
        }
      }

      & .top-nav-template-box {
        width: 100%;
        height: 44px;
        cursor: default;
        border-bottom: 1px solid lightgray;
        & .top-nav-content {
          & span {
            font-size: 1em;
          }
        }
      }
    }
  }
  .preview-remote-part {
    display: flex;
    justify-content: space-between;
    height: auto;
    width: 100%;
    padding: 0 20px;
  }
}

.ls-preview .ls-bottom-nav {
  position: absolute;
  bottom: 0;
  border-top: 1px solid lightgray;

  width: 100%;
  display: flex;

  .nav-item {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #b9b9bd;
    &:hover {
      color: var(--primary-color, #6089d1);
    }
    .nav-icon {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .nav-label {
      margin-top: 3px;
      font-size: 9px;
      text-align: center;
    }
  }
}

.layout-setting-popup {
  & .top-navigation-preview-wrapper {
    width: 100%;
    height: 50px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: white;
    border: 1px solid lightgray;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;

    & .nav-content {
      height: 40px;
      min-width: 30px;
      border: 1px solid lightgray;
      cursor: grab;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #1d4791;
      transition: 0.3s;

      &.selected {
        background-color: rgba(128, 128, 128, 0.363);
      }

      &:has(svg) {
        justify-content: center;
      }

      & span {
        padding-left: 15px;
        font-size: 20px;
      }

      & svg {
        font-size: 24px;
      }
    }
  }
}
.tnb-custom-wrapper {
  width: 100%;
  padding: 10px;
  border: 1px solid lightgray;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: white;
  .tnb-custom {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & .tnb-card {
      height: 55px;
      min-width: 30px;
      background-color: whitesmoke;
      border-radius: 5px;
      margin: 3px;
      justify-content: center;
      align-items: center;

      &.selected {
        background-color: gray;
        color: white;
      }
      & .card-body {
        padding: 0 1rem;
        display: flex;
        width: 100%;
        height: 100%;
        font-size: 24px;
        align-items: center;

        & img {
          height: 100%;
        }

        &:not(:has(img)) {
          justify-content: center;
        }
      }

      & .tnb-delBtn {
        z-index: 1;
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: tomato;
        color: white;
        top: -10px;
        right: -5px;
        cursor: pointer;
      }
    }
  }
}

.menu-selector-wrapper {
  padding: 10px;
  background-color: white;
  border-radius: 3px;

  &.selected {
    background-color: rgb(246, 249, 255);
    border: #7aa8f8 1px solid;
    box-shadow: 5px 5px 5px rgb(197, 197, 197);
  }
  & img {
    width: 100%;
    height: 380px;
    cursor: pointer;
  }
}
