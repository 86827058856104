.icon-popup-body button {
  width: 100%;
  margin-top: 3px;
  border-radius: 5px;
  border: 2px solid transparent;
  min-height: 64px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}
.icon-popup-body button .daaf-icon {
  display: inline-block;
  width: 26px;
  height: 26px;
}

.edit-panel.sirius .btn.btn-default {
  height: 38px;
  border-radius: 8px;
  min-width: 38px;
  border: 1px solid #dfdfdf;
}

.edit-panel.sirius .btn-group.builder-block .btn {
  margin: 0;
  padding: 0;
}

/*
.edit-panel.sirius
  .btn-group.builder-block
  .editor-component-wrap:not(:nth-child(2))
  .btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.edit-panel.sirius
  .btn-group.builder-block
  .editor-component-wrap:not(:nth-last-child(2))
  .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
*/
