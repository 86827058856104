.edit-panel.sirius {
  --grid-border-color: #e9e9e9;
  --grid-readonly-cell-bg: #f5f5f5;
  --grid-min-height: 100px;
  --grid-header-bg: #f6f6f6;
  --grid-header-color: #454545;
  --grid-cell-min-height: 46px;
  --grid-header-cell-min-height: 40px;
}

.edit-panel.sirius .editor-grid {
  font-family: "Pretendard", Pretendard, -apple-system, BlinkMacSystemFont,
    "Noto Sans KR", sans-serif !important;
}

/*toolbar button*/
.edit-panel.sirius .editor-gird-toolbar-buttons > button {
  border-radius: 8px !important;
  border: 1px solid #dfdfdf !important;
  margin-left: 10px;
  height: 38px !important;
  padding: 6px 14px 6px 14px !important;
  font-size: 14px !important;
  color: #747474 !important;
}

/*grid border 잡기 -> 전체 wrap으로 border 생성할 경우, 파란색 selector 박스 짤림*/

.edit-panel.sirius .editor-grid-wrap {
  border-radius: 8px;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 2px;
}

.edit-panel.sirius .editor-grid-wrap > div {
  border-top: 1px solid var(--grid-border-color);
  border-bottom: 1px solid var(--grid-border-color);
}
.edit-panel.sirius .uni-table .editor-grid-columns .editor-grid-header {
  border-top: none !important;
}

.edit-panel.sirius .editor-grid-column {
  border-bottom: none;
  background: transparent;
}

.edit-panel.sirius .editor-grid-header {
  border-bottom: var(--grid-border-color) 1px solid;
}

/*-------------------border end--------------------------*/

/*grid header
-----------------------------------
*/
.edit-panel.sirius .editor-grid-columns .editor-grid-header {
  background: var(--grid-header-bg);
  color: var(--grid-header-color);
  font-weight: 600;
}

.edit-panel.sirius .editor-grid-header .grid-header-title .grid-label {
  padding-left: 16px;
}

.edit-panel.sirius .editor-grid-header .grid-header-title .grid-header-icon {
  background: transparent;
}

/*grid Title*/
.edit-panel.sirius .editor-grid-title {
  align-items: center;
}

/*grid cell*/
.edit-panel.sirius .editor-grid-column:not(.grid-row-header) {
  padding-left: 16px;
}

/*grid cell*/
.edit-panel.sirius .editor-grid-column.read-only {
  background-color: var(--grid-readonly-cell-bg);
}

.edit-panel.sirius .grid-header-icon {
  background-color: transparent !important;
}

.edit-panel.sirius
  .editor-grid-columns:has(.editor-grid-header .grid-header-icon > *) {
  width: 60px !important;
}

.edit-panel.sirius .editor-grid-header {
  border-right: 1px solid var(--grid-border-color);
}

.edit-panel.sirius
  .page-container
  .page
  .editor-grid-columns:not(.last-column)
  .editor-grid-header::after {
  height: 0;
}
